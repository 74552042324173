<template>
  <div>
    <el-table
      :data="tableData"
      border
      row-key="id"
      align="left">
      <el-table-column
        v-for="(item, index) in col"
        :key="`col_${index}`"
        :prop="dropCol[index].prop"
        :label="item.label">
      </el-table-column>
    </el-table>
    <pre style="text-align: left">
      {{dropCol}}
    </pre>
    <hr/>
    <pre style="text-align: left">
      {{tableData}}
    </pre>
  </div>
</template>
<script>
  import Sortable from "sortablejs";
  export default {
    data() {
      return {
        col: [
          {
            label: "半月保",
            prop: "date",
          },
          {
            label: "半月保",
            prop: "date1",
          },
          {
            label: "半月保",
            prop: "date2",
          },
          {
            label: "半月保",
            prop: "date3",
          },
          {
            label: "半月保",
            prop: "date4",
          },
          {
            label: "季度保",
            prop: "date5",
          },
          {
            label: "半月保",
            prop: "date6",
          },
          {
            label: "半月保",
            prop: "date7",
          },
          {
            label: "半月保",
            prop: "date8",
          },
          {
            label: "半月保",
            prop: "date9",
          },
          {
            label: "半月保",
            prop: "date10",
          },
          {
            label: "半年保",
            prop: "date11",
          },
        ],
        dropCol: [
          {
            label: "半月保",
            prop: "date",
          },
          {
            label: "半月保",
            prop: "date1",
          },
          {
            label: "半月保",
            prop: "date2",
          },
          {
            label: "半月保",
            prop: "date3",
          },
          {
            label: "半月保",
            prop: "date4",
          },
          {
            label: "季度保",
            prop: "date5",
          },
          {
            label: "半月保",
            prop: "date6",
          },
          {
            label: "半月保",
            prop: "date7",
          },
          {
            label: "半月保",
            prop: "date8",
          },
          {
            label: "半月保",
            prop: "date9",
          },
          {
            label: "半月保",
            prop: "date10",
          },
          {
            label: "半年保",
            prop: "date11",
          },
        ],
        tableData: [
          {
            id: "1",
            date: "2016-05-02",
            date1: "2016-05-02",
            date2: "2016-05-02",
            date3: "2016-05-02",
          },

        ],
      };
    },
    mounted() {
      // 阻止默认行为
      document.body.ondrop = function(event) {
        event.preventDefault();
        event.stopPropagation();
      };
      // this.rowDrop();
      this.columnDrop();
    },
    methods: {
      //行拖拽
      rowDrop() {
        const tbody = document.querySelector(".el-table__body-wrapper tbody");
        const _this = this;
        Sortable.create(tbody, {
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.tableData.splice(oldIndex, 1)[0];
            _this.tableData.splice(newIndex, 0, currRow);
          },
        });
      },
      //列拖拽
      columnDrop() {
        const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
        this.sortable = Sortable.create(wrapperTr, {
          animation: 180,
          delay: 0,
          onEnd: evt => {
            console.log(evt.oldIndex,169);
            // const oldItem = this.dropCol[evt.oldIndex];
            const list = this.dropCol.splice(evt.oldIndex, this.dropCol.length-evt.oldIndex);
            console.log(list,this.dropCol);
            this.dropCol=list.concat(this.dropCol);

            console.log(this.dropCol,list);
          },
        });
      },
      formatCol() {
        
      },
    },
  };
</script>
